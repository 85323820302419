import { Controller } from "@hotwired/stimulus"
import Inputmask from "inputmask";

// Connects to data-controller="inputmask"
export default class extends Controller {

  connect () {
    Inputmask({
      "mask": "DE9{2} 9{4} 9{4} 9{4} 9{4} 9{2}"
    }).mask(this.element);
  }
}
