import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="iban-check"
export default class extends Controller {
  static targets = [ "input" ]
  static values = {
    url: String,
    param: String
  }


  keyup(event) {
    let params = new URLSearchParams()
    params.append(this.paramValue, event.target.value)

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
  }
}
